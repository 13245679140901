import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Certifications from "../../content/notes/certifications.mdx";
import Layout from "../gatsby-theme-blog/components/layout.js";
import Header from "../gatsby-theme-blog/components/header";
import SEO from "../gatsby-theme-blog/components/seo";
import Footer from "../gatsby-theme-notes/components/footer";
import Index from "../../content/index.mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  ...props
}) => <Layout {...props} location={props.location} title="pedro.fyi">
    <SEO title=" " favicon="" />
    <Index />
  </Layout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`;`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      